import React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px);
  padding-top: 16px;
  padding-bottom: 16px;
`

const PageLoader = () => {
  return (
    <Wrapper>
      <Spin />
    </Wrapper>
  )
}

export default PageLoader
