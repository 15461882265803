import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useFetchOwnerData, useFetchOwnerStats } from 'state/owner/hooks'
import GlobalHeader from 'components/GlobalHeader'
import GlobalFooter from 'components/GlobalFooter'
import Menu from 'components/Menu'
import SuspenseWithChunkError from 'components/SuspenseWithChunkError'
import PageLoader from 'components/Loader/PageLoader'

import 'antd/dist/antd.css'
import 'styles/antd-overrides.css'

// Route-based code splitting
const Home = lazy(() => import('./Home'))
const Map = lazy(() => import('./Map'))
const Listings = lazy(() => import('./Listings'))
const Properties = lazy(() => import('./Properties'))
const Listing = lazy(() => import('./Listing'))
const ListingOld = lazy(() => import('./ListingOld'))
const Property = lazy(() => import('./Property'))
const PropertyOld = lazy(() => import('./PropertyOld'))
const Registration = lazy(() => import('./Registration'))
const Complaints = lazy(() => import('./Complaints'))
const NotFound = lazy(() => import('./NotFound'))

const Dashboard = () => {
  useFetchOwnerData()
  useFetchOwnerStats()

  return (
    <>
      <GlobalHeader />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/map">
              <Map />
            </Route>
            <Route path="/listings">
              <Listings />
            </Route>
            <Route path="/properties">
              <Properties />
            </Route>
            <Route path="/listing/:id">
              <Listing />
            </Route>
            <Route path="/listing-old/:id">
              <ListingOld />
            </Route>
            <Route path="/property-old/:id">
              <PropertyOld />
            </Route>
            <Route path="/property/:id">
              <Property />
            </Route>
            <Route path="/registration">
              <Registration />
            </Route>
            <Route path="/complaints">
              <Complaints />
            </Route>

            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
          <GlobalFooter />
        </SuspenseWithChunkError>
      </Menu>
    </>
  )
}

export default React.memo(Dashboard)
