import axios from 'axios'
import { LOGINAPI_ADDRESS } from 'config'
import { decryptJSON } from 'utils/functions'

interface UsersAccessListState {
  accessList: []
  isInitalized: false
  isLoading: true
  hasError: false
}

export const usersAccessFullList = async (): Promise<any> => { // Promise<UsersAccessListState> => {
  const tokenData = localStorage.getItem('bba-token') ? JSON.parse(localStorage.getItem('bba-token')) : null
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenData.token}`,
    },
  }
  const url = `${LOGINAPI_ADDRESS}/api/users/councils-access`
  let { data } = await axios.get(url, config)
  try{
    data = decryptJSON(data)
  }catch(err){
    // ... leave as is
  }
  data = data.sort( (a, b) => {
    if (a.ownerName < b.ownerName){
      return -1
    }
    return 0;
  })
  // console.log("data",data)
  return data
}
