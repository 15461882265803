import React from 'react'

const LOGO_LOCATION = '/images/bnb_guard_rev.png'
const LOGO_WHITE_LOCATION = '/images/bnb_guard_rev.png'
const LOGO_MOBILE_LOCATION = '/images/bnb_guard.png'

interface LogoProps {
  isMobile: boolean
  isColoured?: boolean
}

const Logo: React.FC<LogoProps> = ({ isMobile, isColoured }) => {
  const logo = isColoured ? LOGO_LOCATION : isMobile ? LOGO_MOBILE_LOCATION : LOGO_WHITE_LOCATION
  return <img src={logo} alt="BnbGuard Logo" style={{ width: '100%' }} />
}

export default React.memo(Logo, (prev, next) => prev.isMobile === next.isMobile)
