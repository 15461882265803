import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import type { State, AccountState } from '../types'
import { fetchAccountData, fetchAccountFailed, setAccountData } from '.'

// export const useAccount = (): AccountState => {
//   const account = useSelector((state: State) => state.account)

//   if (!account.isAuthenticated) {
//     const ownerName = localStorage.getItem('bbg-account')
//     const ownerId = localStorage.getItem('bbg-owner')
//     const data = localStorage.getItem('bba-token')
//     // removed section allowing for multiple
//     // if (ownerName && ownerId) {
//     // end
//     setAccountData(ownerName, ownerId, data)
//     return {
//       ownerName,
//       ownerId,
//       shortName,
//       role,
//       username,
//       isLoading,
//       hasError,
//     }
//     // }
//   }

//   return account
// }

export const useAccount = (): AccountState => {
  const dispatch = useAppDispatch()
  const account = useSelector((state: State) => state.account)

  useEffect(() => {
    if (!account.isAuthenticated) {
      const accessToken = localStorage.getItem('bba-token')
      const ownerName = localStorage.getItem('bbg-account')
      const ownerId = localStorage.getItem('bbg-owner')

      // const ownerName = ''
      if (accessToken) {
        dispatch(setAccountData(ownerName, ownerId, accessToken))
        dispatch(fetchAccountData(ownerName, accessToken))
      } else {
        dispatch(fetchAccountFailed())
      }
    }
  }, [dispatch, account.isAuthenticated])
  return account
}

export const useFetchAccount = (ownerName) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (ownerName) {
      const accessToken = localStorage.getItem('bba-token')
      dispatch(fetchAccountData(ownerName, accessToken))
    }
  }, [dispatch, ownerName])
}
