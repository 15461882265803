import React from 'react'
// import { useLocation } from 'react-router-dom'

const GlobalFooter = () => {
  // const location = useLocation()
  return (
    <footer className="bnbguard-footer">&copy; 2015 - {new Date().getFullYear()} BNBGuard</footer>
  )
}

export default GlobalFooter
