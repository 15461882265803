import axios from 'axios'
import { LOGINAPI_ADDRESS } from 'config'

interface UsersDataResponse {
  access_token: string // eslint-disable-line
  shortName: string
  userId: string
  isAdmin: string
  role: string
  username: string
  phonenumber: string
  pic: string
  _id: string
  councilsAcList: []
  buildingsAcList: []
}

export const getUsersData = async (accessToken: string): Promise<UsersDataResponse> => {
  const data = localStorage.getItem('bba-token') ? JSON.parse(localStorage.getItem('bba-token')) : null

  return data
}

export const usersLogin = async (username: string, password: string): Promise<any> => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }
  const url = `${LOGINAPI_ADDRESS}/api/users/login`
  const { data } = await axios.post(url, {
    username,
    password,
    site: "council.bnbguard.com.au",
  })
  return data
}

export const usersVerifyTwofactorCode = async (code: string, username: string): Promise<any> => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }
  const url = `${LOGINAPI_ADDRESS}/api/users/verify-code`
  const { data } = await axios.post(url, {
    code,
    username,
  })
  return data
}

export const usersTwoFactor = async (username: string): Promise<any> => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }
  const url = `${LOGINAPI_ADDRESS}/api/users/request-two-factor`
  const { data } = await axios.post(url, {
    username,
  })
  return data
}

export const users = async (code: string, username: string): Promise<any> => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }
  const url = `${LOGINAPI_ADDRESS}/api/users/verify-code`
  const { data } = await axios.post(url, {
    code,
    username,
  })

  return data
}

export const usersReset = async (username: string): Promise<any> => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }
  const url = `${LOGINAPI_ADDRESS}/api/users/request-reset-password`
  const { data } = await axios.post(url, {
    username,
  })
  return data
}

export const userUpdate = async (values: string): Promise<any> => {
  const tokenData = localStorage.getItem('bba-token') ? JSON.parse(localStorage.getItem('bba-token')) : null
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenData.token}`,
    },
  }
  const url = `${LOGINAPI_ADDRESS}/api/users/profile`
  const { data } = await axios.put(url, values, config)

  return data
}
