import axios from 'axios'
import { API_ADDRESS } from 'config'
import { ListingMapItem, PropertyMapItem } from 'config/constants/types'

export const getMapData = async (
  ownerId: string,
): Promise<{
  listings: {
    _id: string
    site: string
    listingId: string
    location: any
    isExact: string
    suburbId: string
    isRemoved: boolean
    isNew: boolean
    matchType: string
  }[]
  properties: {
    _id: string
    propertyId: string
    isLevied: string
    matchType: string
    suburbId: string
    location: any
    created: string
    isNew: boolean
  }[]
}> => {
  const url = `${API_ADDRESS}/dashboard/map/data?ownerId=${ownerId}`
  const { data } = await axios.get(url)
  return data
}

export const getMapItem = async (itemId: string, itemType: string): Promise<ListingMapItem | PropertyMapItem> => {
  const url = `${API_ADDRESS}/dashboard/map/item?itemType=${itemType}&itemId=${itemId}`
  const { data } = await axios.get(url)
  return data
}
