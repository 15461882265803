import axios from 'axios'
import { decryptJSON } from 'utils/functions'
import { API_ADDRESS } from 'config'
import { PropertyData } from '../types'

export const getPropertyData = async (_id: string): Promise<PropertyData> => {
  const url = `${API_ADDRESS}/dashboard/property?id=${_id}`
  const { data } = await axios.get(url)
  try{
    return decryptJSON(data)
  }catch(err){
    return data
  }
}
