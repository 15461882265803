import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { SuburbsDataType } from 'config'
import type { State, OwnerState, Suburb } from '../types'
import { useAccount } from '../account/hooks'
import { fetchOwnerData, fetchSuburbsData, fetchOwnerStats } from '.'

export const useFetchOwnerData = () => {
  const dispatch = useAppDispatch()
  const { ownerId } = useAccount()
  useEffect(() => {
    if (ownerId) {
      dispatch(fetchOwnerData(ownerId))
    }
  }, [dispatch, ownerId])
}

export const useOwner = (): Partial<OwnerState> => {
  const { data, isInitialized, isLoading } = useSelector((state: State) => state.owner)

  return { data, isInitialized, isLoading }
}

export const useSuburbs = (): {
  suburbs: Suburb[]
  suburbsListingsInitialized: boolean
  suburbsPropertiesInitialized: boolean
  suburbsLoading: boolean
} => {
  const { data, suburbsListingsInitialized, suburbsPropertiesInitialized, suburbsLoading } = useSelector(
    (state: State) => state.owner,
  )
  const { suburbs } = data

  return { suburbs, suburbsListingsInitialized, suburbsPropertiesInitialized, suburbsLoading }
}

export const useFetchSuburbsData = (type: SuburbsDataType) => {
  const dispatch = useAppDispatch()
  const { data, isInitialized } = useOwner()
  const { _id: ownerId } = data

  useEffect(() => {
    if (isInitialized) {
      dispatch(fetchSuburbsData(ownerId, type))
    }
  }, [dispatch, ownerId, type, isInitialized])
}

export const useFetchOwnerStats = () => {
  const dispatch = useAppDispatch()
  const { data, isInitialized } = useOwner()
  const { _id: ownerId } = data

  useEffect(() => {
    if (isInitialized) {
      dispatch(fetchOwnerStats(ownerId))
    }
  }, [dispatch, ownerId, isInitialized])
}

export const useOwnerStats = (): Partial<OwnerState> => {
  const { stats, statsInitialized, statsLoading } = useSelector((state: State) => state.owner)

  return { stats, statsInitialized, statsLoading }
}
