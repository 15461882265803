import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useAccount } from 'state/account/hooks'

interface PrivateRouteProps {
  children: any
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { isAuthenticated, isLoading } = useAccount()
  const location = useLocation()

  return (
    <Route
      path={location.pathname}
      render={() =>
        isAuthenticated
          ? children
          : !isLoading && (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location.pathname },
                }}
              />
            )
      }
    />
  )
}

export default PrivateRoute
