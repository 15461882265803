export const API_ADDRESS      = process.env.REACT_APP_API_ADDRESS
// export const LOGINAPI_ADDRESS = process.env.LOGINAPI_ADDRESS

export enum SuburbsDataType {
  listing = 'listing',
  property = 'property',
}

export enum SuburbDataType {
  listings = 'listings',
  properties = 'properties',
}

export const PLACES_API_KEY = 'AIzaSyBPBPA2YS0YhAM-Sox275fhV-WV9h8uFp0'
export const MAPBOX_TOKEN = 'pk.eyJ1IjoiYm5iZ3VhcmQ4ODgiLCJhIjoiY2p3Nzh5cXQ1MWkwbTQ1czBtNm84NDJlbSJ9.SPGwPhu1vILcPFOglKXvDw'

// export const LOGINAPI_ADDRESS  = 'http://localhost:5010'                   // login-api (local)
export const LOGINAPI_ADDRESS  = 'https://users.bnbguard.com.au/'          // login-api

// export const LOGINAPI_ADDRESS  = 'https://staging-users.bnbguard.com.au'   // login-api (staging)
// export const LOGINAPI_ADDRESS  = "https://staging-api.bnbguard.com.au"     // api-main
// export const LOGINAPI_ADDRESS  = 'http://localhost:8002'                   // api-main
