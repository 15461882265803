import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import type { AccountState } from '../types'
import { getAccountData, doLogin } from './helpers'

const noAccountConfig = {
  ownerName: '',
  ownerId: undefined,
  username: null,
  phonenumber: null,
  userId: null,
  role: null,
  shortName: null,
  selectedUser: null,
  isAuthenticated: false,
  isLoading: true,
  hasError: false,
}

interface SetAccountPayload {
  ownerName: string
  ownerId: string
  username: string
  phonenumber: string
  userId: string
  role: string
  shortName: string
}

const initialState: AccountState = { ...noAccountConfig }

export const accountSlice = createSlice({
  name: 'Account',
  initialState,
  reducers: {
    fetchAccount: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    setAccount: (state, action: PayloadAction<SetAccountPayload>) => {

      state.ownerName = action.payload.ownerName
      state.ownerId = action.payload.ownerId
      state.username = action.payload.username
      state.phonenumber = action.payload.phonenumber
      state.userId = action.payload.userId
      state.role = action.payload.role
      state.shortName = action.payload.shortName
      state.isLoading = false
      state.isAuthenticated = true
      state.hasError = false
    },
    updateAccount: (state, action: PayloadAction<SetAccountPayload>) => {
      state.ownerName = action.payload.ownerName
      state.ownerId = action.payload.ownerId
      state.username = action.payload.username
      state.phonenumber = action.payload.phonenumber
      state.userId = action.payload.userId
      state.role = action.payload.role
      state.shortName = action.payload.shortName
      state.isLoading = false
      state.isAuthenticated = true
      state.hasError = false
    },
    logoutAccount: (state) => {
      state.ownerName = ''
      state.ownerId = undefined
      state.username = null
      state.phonenumber = null
      state.userId = null
      state.role = null
      state.shortName = null
      state.isAuthenticated = false
      state.isLoading = false
      state.hasError = false
    },
    fetchAccountFailed: (state) => {
      state.ownerName = ''
      state.ownerId = undefined
      state.username = null
      state.phonenumber = null
      state.userId = null
      state.role = null
      state.shortName = null
      state.isLoading = false
      state.isAuthenticated = false
      state.hasError = true
    },
  },
})

export const fetchAccountData = (oName: string, accessToken: string) => async (dispatch) => {
  try {
    dispatch(fetchAccount())
    const { username, phonenumber, userId, role, shortName } = localStorage.getItem('bba-token')
      ? JSON.parse(localStorage.getItem('bba-token'))
      : null
    const { ownerName, ownerId } = await getAccountData(oName)
    dispatch(
      setAccount({
        ownerName,
        ownerId,
        username,
        phonenumber,
        userId,
        role,
        shortName,
      }),
    )
  } catch (error) {
    // localStorage.clear()
    // sessionStorage.clear()
    message.error('Account not found')
    dispatch(fetchAccountFailed())
  }
}

export const authenticateUser = (email: string, password: string) => async (dispatch) => {
  try {
    dispatch(fetchAccount())
    const { access_token: accessToken } = await doLogin(email, password)
    localStorage.setItem('bba-token', accessToken)
    const { ownerId, ownerName, username, phonenumber, userId, role, shortName } = await getAccountData(accessToken)

    dispatch(
      setAccount({
        ownerName,
        ownerId,
        username,
        phonenumber,
        userId,
        role,
        shortName,
      }),
    )
  } catch (error) {
    // localStorage.clear()
    // sessionStorage.clear()
    message.error('Account not found Auth')
    dispatch(fetchAccountFailed())
  }
}

export const setAccountData = (ownerName: string, ownerId: string, accessToken: string) => async (dispatch) => {
  try {
    const { username, phonenumber, userId, role, shortName } = await getAccountData(accessToken)
    dispatch(
      setAccount({
        ownerName,
        ownerId,
        username,
        phonenumber,
        userId,
        role,
        shortName,
      }),
    )
  } catch (error) {
    localStorage.clear()
    sessionStorage.clear()
    message.error('Account not found')
    dispatch(fetchAccountFailed())
  }
}

export const updateAccountData = (ownerName: string, ownerId: string) => async (dispatch) => {
  try {
    dispatch(fetchAccount())
    const { username, phonenumber, userId, role, shortName } = localStorage.getItem('bba-token')
      ? JSON.parse(localStorage.getItem('bba-token'))
      : null
    localStorage.setItem('bbg-account', ownerName)
    localStorage.setItem('bbg-owner', ownerId)

    dispatch(
      setAccount({
        ownerName,
        ownerId,
        username,
        phonenumber,
        userId,
        role,
        shortName,
      }),
    )
  } catch (error) {
    localStorage.clear()
    sessionStorage.clear()
    message.error('Account not found')
    dispatch(fetchAccountFailed())
  }
}

// Actions
export const { fetchAccount, setAccount, updateAccount, logoutAccount, fetchAccountFailed } = accountSlice.actions

export default accountSlice.reducer
