import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import type { State, UsersState } from '../types'
import { fetchUsersData, fetchUsersFailed } from '.'

export const useUsers = (): UsersState => {
  const dispatch = useAppDispatch()
  const users = useSelector((state: State) => state.users)

  useEffect(() => {
    if (!users.isAuthenticated) {
      const accessToken = localStorage.getItem('bba-token')
      if (accessToken) {
        dispatch(fetchUsersData(accessToken))
      } else {
        dispatch(fetchUsersFailed())
      }
    }
  }, [dispatch, users.isAuthenticated])

  return users
}
