import axios from 'axios'
import { API_ADDRESS, SuburbDataType } from 'config'
import type { Listing, Property, SuburbStats } from 'state/types'
import { decryptJSON } from 'utils/functions'

export const getSuburbData = async (
  ownerId: string,
  name: string,
  dataType: SuburbDataType,
): Promise<Listing[] | Property[]> => {
  const url = `${API_ADDRESS}/dashboard/suburb/data?ownerId=${ownerId}&suburbName=${name}&dataType=${dataType}`
  const { data } = await axios.get(url)

  try{
    return decryptJSON(data)
  }catch(err){
    return data
  }
}

export const getSuburbCsv = async (ownerId: string, dataType: SuburbDataType, suburbName: string): Promise<any[]> => {
  const url = `${API_ADDRESS}/dashboard/suburb/csv?ownerId=${ownerId}&dataType=${dataType}&suburbName=${suburbName}`
  const { data } = await axios.get(url)

  try{
    return decryptJSON(data)
  }catch(err){
    return data
  }
}

export const getSuburbStats = async (ownerId: string, suburbName: string): Promise<SuburbStats> => {
  const url = `${API_ADDRESS}/dashboard/suburb/stats?ownerId=${ownerId}&suburbName=${suburbName}`
  const { data } = await axios.get(url)

  try{
    return decryptJSON(data)
  }catch(err){
    return data
  }
}
