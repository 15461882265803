import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import { Form, Input, Button } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useUsers } from 'state/users/hooks'
import { fetchUsersFailed } from 'state/users'
import { resetUsers, fetchLoginFailed } from 'state/login-progress'
import history from 'routerHistory'

const ResetForm = () => {
  const { isAuthenticated, isLoading, hasError } = useUsers()
  const dispatch = useAppDispatch()

  //   [active, setActive] = useState('loginform')

  const [formLoading, setFormLoading] = useState(false)

  const onFinishReset = useCallback(
    (values) => {
      setFormLoading(true)
      const { username } = values
      dispatch(resetUsers(username))
    },
    [dispatch],
  )

  /* Back to login clear state */
  const BacktoLogin = useCallback(() => {
    localStorage.clear()
    sessionStorage.clear()
    dispatch(fetchUsersFailed())
    dispatch(fetchLoginFailed())
  }, [dispatch])

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        history.push('/')
      }
    }
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    if (hasError) {
      setFormLoading(false)
    }
  }, [hasError])

  return (
    <Form name="login" onFinish={onFinishReset} size="large">
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: '100%', background: '#254874' }} loading={formLoading}>
          Request New Password
        </Button>
        <Button
          type="link"
          style={{ fontSize: '12px', marginTop: '0px', color: '#254874', textAlign: 'center', width: '100%' }}
          onClick={() => BacktoLogin()}
        >
          Back to Login
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ResetForm
