import React from 'react'
import { Layout } from 'antd'
// import { Layout, Grid } from 'antd'
import MenuItems from './MenuItems'

const { Content, Sider } = Layout
// const { useBreakpoint } = Grid

const Menu: React.FC = ({ children }) => {
  // const { lg } = useBreakpoint()
  // const isMobile = lg === false

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="60"
        width="114"
        /*
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 80,
          paddingTop: 16,
          paddingBottom: 16,
          backgroundColor: '#0a2327',
        }}
        */
       className="layout-slider"
      >
        <MenuItems />
      </Sider>
      <Layout style={{ marginLeft: 0, marginTop: 0, paddingBottom:'45px', position: 'relative', backgroundColor: '#fefefe' }}>
        { /* height: 'calc(100vh - 120px)', */ }
        <Content
          style={{
            padding: 0,
            margin: 0,
            // minHeight: 'calc(100vh - 88px)',
            /*
            border:'2px solid blue',
            position:'absolute',
            top:'0px',
            left: '0px',
            right: '0px',
            bottom:'0px',            
            */
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default Menu
