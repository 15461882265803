import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SuburbDataType } from 'config'
import { getSuburbData, getSuburbStats } from './helpers'
import type { SuburbState, SuburbStats } from '../types'

const initialState: SuburbState = {
  listings: [],
  properties: [],
  stats: null,
  isLoading: true,
  isInitialized: false,
  statsLoading: false,
  statsInitialized: false,
}

export const suburbSlice = createSlice({
  name: 'Suburb',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true
    },
    fetchFailed: (state) => {
      state.isLoading = false
      state.isInitialized = false
    },
    fetchSucceeded: (state, action: PayloadAction<{ data: any; dataType: SuburbDataType }>) => {
      state.isLoading = false
      state.isInitialized = true
      state[action.payload.dataType] = action.payload.data
    },
    fetchStatsStart: (state) => {
      state.statsLoading = true
    },
    fetchStatsFailed: (state) => {
      state.statsLoading = false
      state.statsInitialized = false
    },
    fetchStatsSucceeded: (state, action: PayloadAction<SuburbStats>) => {
      state.statsLoading = false
      state.statsInitialized = true
      state.stats = action.payload
    },
  },
})

export const { fetchStart, fetchFailed, fetchSucceeded, fetchStatsStart, fetchStatsFailed, fetchStatsSucceeded } =
  suburbSlice.actions

export const fetchSuburbData = (ownerId, name, type) => async (dispatch) => {
  try {
    dispatch(fetchStart())
    const suburb = await getSuburbData(ownerId, name, type)
    dispatch(
      fetchSucceeded({
        data: suburb,
        dataType: type,
      }),
    )
  } catch (error) {
    dispatch(fetchFailed())
  }
}

export const fetchSuburbStats = (ownerId, suburbName: string) => async (dispatch) => {
  try {
    dispatch(fetchStatsStart())
    const suburbStats = await getSuburbStats(ownerId, suburbName)
    dispatch(fetchStatsSucceeded(suburbStats))
  } catch (error) {
    dispatch(fetchStatsFailed())
  }
}

export default suburbSlice.reducer
