import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAccount } from 'state/account/hooks'
import { Layout } from 'antd'
import PrivateRoute from 'PrivateRoute'
import Dashboard from './views'
import Login from './views/Login'
import history from './routerHistory'

import 'antd/dist/antd.css'
import 'styles/antd-overrides.css'

const App: React.FC = () => {
  const { isAuthenticated, isLoading } = useAccount()
  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        history.push('/login')
      }
    }
  }, [isAuthenticated, isLoading])

  return (
    <Layout>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      </Switch>
    </Layout>
  )
}

export default App
