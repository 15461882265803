import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SuburbsDataType } from 'config'
import { getOwnerData, getSuburbsData, getOwnerStats } from './helpers'
import type { OwnerState, Owner, Suburb, OwnerStats } from '../types'

const noOwnerConfig = {
  _id: undefined,
  name: '',
  nameLong: '',
  state: '',
  country: '',
  bbox: [0, 0, 0, 0],
  suburbs: [],
}

const initialState: OwnerState = {
  data: noOwnerConfig,
  stats: null,
  isInitialized: false,
  isLoading: true,
  suburbsPropertiesInitialized: false,
  suburbsListingsInitialized: false,
  suburbsLoading: true,
  statsInitialized: false,
  statsLoading: true,
}

export const ownerSlice = createSlice({
  name: 'Owner',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true
    },
    fetchFailed: (state) => {
      state.isLoading = false
      state.isInitialized = false
    },
    fetchSucceeded: (state, action: PayloadAction<Owner>) => {
      state.isLoading = false
      state.isInitialized = true
      state.data = action.payload
    },
    fetchSuburbsStart: (state) => {
      state.suburbsLoading = true
    },
    fetchSuburbsFailed: (state) => {
      state.suburbsLoading = false
      state.suburbsListingsInitialized = false
      state.suburbsPropertiesInitialized = false
    },
    fetchSuburbsSuceeeded: (state, action: PayloadAction<{ data: Suburb[]; dataType: SuburbsDataType }>) => {
      state.suburbsLoading = false
      action.payload.data.forEach((suburbDataItem) => {
        if (action.payload.dataType === SuburbsDataType.listing) {
          const index = state.data.suburbs.findIndex((suburb) => suburb._id === suburbDataItem._id)
          if (index < 0) {
            const unassignedExists = state.data.suburbs.some((item) => item._id === 'unassigned')
            if (!unassignedExists) {
              state.data.suburbs.push({
                _id: 'unassigned',
                name: 'unassigned',
                nameLong: 'unassigned',
                bbox: [],
                listingsData: suburbDataItem.listingsData,
              })
            }
          } else {
            state.data.suburbs[index] = { ...state.data.suburbs[index], listingsData: suburbDataItem.listingsData }
          }
          state.suburbsListingsInitialized = true
        }
        if (action.payload.dataType === SuburbsDataType.property) {
          const index = state.data.suburbs.findIndex((suburb) => suburb._id === suburbDataItem._id)
          if (index < 0) {
            const unassignedExists = state.data.suburbs.some((item) => item._id === 'unassigned')
            if (!unassignedExists) {
              state.data.suburbs.push({
                _id: 'unassigned',
                name: 'unassigned',
                nameLong: 'unassigned',
                bbox: [],
                propertiesData: suburbDataItem.propertiesData,
              })
            }
          } else {
            state.data.suburbs[index] = { ...state.data.suburbs[index], propertiesData: suburbDataItem.propertiesData }
          }
          state.suburbsPropertiesInitialized = true
        }
      })
    },
    fetchStatsStart: (state) => {
      state.statsLoading = true
    },
    fetchStatsFailed: (state) => {
      state.statsLoading = false
      state.statsInitialized = false
    },
    fetchStatsSucceeded: (state, action: PayloadAction<OwnerStats>) => {
      state.statsLoading = false
      state.statsInitialized = true
      state.stats = action.payload
    },
  },
})

export const {
  fetchStart,
  fetchFailed,
  fetchSucceeded,
  fetchSuburbsStart,
  fetchSuburbsFailed,
  fetchSuburbsSuceeeded,
  fetchStatsStart,
  fetchStatsFailed,
  fetchStatsSucceeded,
} = ownerSlice.actions

export const fetchOwnerData = (ownerId: string) => async (dispatch) => {
  try {
    dispatch(fetchStart())
    const owner = await getOwnerData(ownerId)
    dispatch(fetchSucceeded(owner))
  } catch (error) {
    dispatch(fetchFailed())
  }
}

export const fetchSuburbsData = (ownerId: string, type: SuburbsDataType) => async (dispatch) => {
  try {
    dispatch(fetchSuburbsStart())
    const suburbsData = await getSuburbsData(ownerId, type)
    dispatch(
      fetchSuburbsSuceeeded({
        data: suburbsData,
        dataType: type,
      }),
    )
  } catch (error) {
    dispatch(fetchSuburbsFailed())
  }
}

export const fetchOwnerStats = (ownerId: string) => async (dispatch) => {
  try {
    dispatch(fetchStatsStart())
    const ownerStats = await getOwnerStats(ownerId)
    dispatch(fetchStatsSucceeded(ownerStats))
  } catch (error) {
    dispatch(fetchStatsFailed())
  }
}

export default ownerSlice.reducer
