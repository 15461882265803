import crypto from 'crypto' // Storing encrypted version of password

const tokenData = localStorage.getItem('bba-token') ? JSON.parse(localStorage.getItem('bba-token')) : null

const algorithm = 'aes-256-cbc'  // Encryption type
const iv = Buffer.from('c63c985e17ccde12c17dba4c6abdac37', 'hex')
const secretKey = Buffer.from('cbf82fb39b5c3915dc9dcea6b9db0edb7574fdb75cef602f0fcb9e6a4e0871a1', 'hex')

// const iv = crypto.randomBytes(16) // iv for Encryption
// const secretKey = crypto.randomBytes(32) // Do not change the secretKey

export function checkToken(data: any){
	if( data?.message && data.message.includes('token') ){
		localStorage.clear()
		sessionStorage.clear()
		window.location.href='/login'
	}
}

export const encryptJSON = (plainJSON) => {
	const cipher = crypto.createCipheriv(algorithm, secretKey, iv)
	let encryptedJSON = cipher.update(JSON.stringify(plainJSON), 'utf8', 'hex')
	encryptedJSON += cipher.final('hex')
	return encryptedJSON
}

export const decryptJSON = (encryptedJSON, functionName = '') => {
    const decipher = crypto.createDecipheriv(algorithm, secretKey, iv);
    let decryptedJSON = decipher.update(encryptedJSON, 'hex', 'utf8');
    decryptedJSON += decipher.final('utf8');
    return JSON.parse(decryptedJSON)
}

export const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = src;
    });
}

export function ucwords(str: string){
	return str?.replace(/\b\w/g, (letter) => letter.toUpperCase()) || str;
}

export function hex2bin(hex: string){
    const bytes = []
    for(let i=0; i< hex.length-1; i+=2)
        bytes.push(parseInt(hex.substr(i, 2), 16));

    return bytes.map( byte => String.fromCharCode(byte) )?.join() ?? ''
    // return String.fromCharCode.apply(String, bytes);    
}

export function bin2hex (bin: string){
  let i = 0
  const l = bin?.length ? bin.length : 0;
  let chr = '';
  let hex = '';
  for (i; i < l; ++i){
    chr = bin.charCodeAt(i).toString(16)
    hex += chr.length < 2 ? `0${chr}` : chr;
  }
  return hex;
}


export function sortObjects(objects: any, field: string, direction: string){
	if (typeof objects?.length === 'undefined'){
		return []
	}
	const newObjects = [...objects]

	return newObjects.sort(function(a,b){
        let field1 = null
        let field2 = null
        if(!Number.isNaN(a?.[field]) || !Number.isNaN(b?.[field]) ){
            field1 = parseFloat(a?.[field] ?? 0);
            field2 = parseFloat(b?.[field] ?? 0);

        }else{
            field1 = a?.[field]?.toString()?.toUpperCase() ?? '';
            field2 = b?.[field]?.toString()?.toUpperCase() ?? '';
        }
        
        if( direction === 'asc') {
            if (field1 < field2){
                return -1;
            }
            if (field1 > field2){
                return 0;
            }
        }
        if( direction === 'desc') {
            if (field1 < field2){
                return 0;
            }
            if (field1 > field2){
                return -1;
            }
        }
        return 0;
	})
}

export function searchListings(searchValue, listings){
    return listings.filter( listing => {
        let matches = 0
        // Multiple word match
        if(searchValue.indexOf(' ') > -1){
            const searchValues = searchValue.split(' ')
            for (let i = 0; i < searchValues.length; i++){
              matches += ( listing?.data?.title && listing.data.title.toLowerCase().includes( searchValues[i].toLowerCase()) )
              || ( listing?.data?.propertyType && listing.data.propertyType.toLowerCase().includes( searchValues[i].toLowerCase()) )
              || ( (listing?.data as any)?.listingType && listing.data.listingType.toLowerCase().includes( searchValues[i].toLowerCase()) )
              || ( listing?.address?.long && listing?.address.long.toLowerCase().includes( searchValues[i].toLowerCase()) )
              || ( listing?.data?.hostName && listing?.data.hostName.toLowerCase().includes( searchValues[i].toLowerCase()) )
              || ( listing?.matchType && listing?.matchType.toLowerCase().includes( searchValues[i].toLowerCase()) )
              || ( listing?.listingId && listing?.listingId.toLowerCase().includes( searchValues[i].toLowerCase()) ) ? 1 : 0;
            }
            if (matches === searchValues.length){
              return true
            }
        }
        // Direct matches
        const matched = ( listing?.data?.title && listing.data.title.toLowerCase().includes(searchValue.toLowerCase()) )
        || ( listing?.data?.propertyType && listing.data.propertyType.toLowerCase().includes(searchValue.toLowerCase()) )
        || ( (listing?.data as any)?.listingType && listing.data.listingType.toLowerCase().includes(searchValue.toLowerCase()) )
        || ( listing?.address?.long && listing?.address.long.toLowerCase().includes(searchValue.toLowerCase()) )
        || ( listing?.data?.hostName && listing?.data.hostName.toLowerCase().includes(searchValue.toLowerCase()) )
        || ( listing?.matchType && listing?.matchType.toLowerCase().includes(searchValue.toLowerCase()) )
        || ( listing?.listingId && listing?.listingId.toLowerCase().includes(searchValue.toLowerCase()) )

        return matched
    }).map( listing => {
        return {
          ...listing,
          ...listing?.data ?? {},
          long: listing?.address?.long ?? '',
          suburb: listing?.address?.suburb ?? '',
        }
      });
}
