import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import { Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useUsers } from 'state/users/hooks'
import { authUsers } from 'state/users'
import { useLoginProgress } from 'state/login-progress/hooks'
import history from 'routerHistory'

const LoginForm = () => {
  const { isAuthenticated, isLoading, hasError } = useUsers()
  const { verifyUser } = useLoginProgress()
  const dispatch = useAppDispatch()

  const [formLoading, setFormLoading] = useState(false)

  const onFinish = useCallback(
    (values) => {
      setFormLoading(true)
      const { username, password } = values
      if (verifyUser === true) {
        dispatch(authUsers(username.trim(), password.trim(), true))
      } else {
        dispatch(authUsers(username.trim(), password.trim(), false))
      }
    },
    [dispatch, verifyUser],
  )

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        history.push('/')
      }
    }
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    if (hasError) {
      setFormLoading(false)
    }
  }, [hasError])

  return (
    <Form name="login" onFinish={onFinish} size="large">
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
      </Form.Item>

      <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%', marginBottom: '0px', background: '#254874' }}
          loading={formLoading}
        >
          Log In
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LoginForm
