import React from 'react'
// import styled from 'styled-components'
// import { useAllAccess } from 'state/users-access/hooks'
// import { Layout, Grid, Col } from 'antd'
// import TopMenu from 'components/TopMenu'
// import AccountMenu from 'components/AccountMenu'
// import { LogoIcon } from '../Icons'
import 'styles/bnbguard.css'

// const { Header } = Layout
// const { useBreakpoint } = Grid

const GlobalHeader = () => {
  return <></>
  /*
  const { lg } = useBreakpoint()
  const isMobile = lg === false
  const [councilId, setCouncilId] = useState(localStorage.getItem('bbg-owner'))
  const { accessList, isLoading } = useAllAccess()
  // console.log("accessList", accessList)
  // <img src="../images/bnb_guard_rev.png" alt="BNBGuard Logo" className="global-header-logo-desktop" />

  return (
    <header className="global-header">
 
      <div className="global-header-logo-holder" style={{display: 'none'}}>
        <img src="../images/bnb_guard_shield.png" alt="BNBGuard Logo" className="global-header-logo-desktop" />
        <img src="../images/bnb_guard_shield.png" alt="BNBGuard Logo" className="global-header-logo-mobile" />
      </div>

      <div className="global-header-heading" style={{display:'none'}}>
        Welcome to BNBGuard Councils
      </div>
      <div className="global-header-account-menu-holder">
        <TopMenu accessList={accessList} /> 
      </div>

    </header>
  )
  */
}

/*

  return (
    <Header
      style={{
        position: 'fixed',
        zIndex: 3,
        width: '100%',
        padding: '0 26px 0 16px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <StyledLogoWrapper>
        <LogoIcon isMobile={isMobile} isColoured={false} />
      </StyledLogoWrapper>

      <h1 style={{ color: '#fff', textTransform: 'uppercase', fontSize: '14px', flexGrow: 0.9, padding: '0 0 0 15px' }}>
        Welcome to BNBGuard Councils Dashboard
      </h1>

      <StyledRightWrapper>
        <AccountMenu />
      </StyledRightWrapper>
    </Header>
  )

*/

export default GlobalHeader
