import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ListingMapItem, PropertyMapItem } from 'config/constants/types'
import { MapData, MapState } from '../types'
import { getMapData, getMapItem } from './helpers'

const initialState: MapState = {
  isInitialized: false,
  isLoading: true,
  isItemLoading: false,
  isItemInitialized: false,
  data: {
    listings: [],
    properties: [],
    item: null,
  },
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true
    },
    fetchFailed: (state) => {
      state.isLoading = false
      state.isInitialized = false
    },
    mapDatafetchSucceeded: (state, action: PayloadAction<MapData>) => {
      state.isLoading = false
      state.isInitialized = true
      state.data.listings = action.payload.listings
      state.data.properties = action.payload.properties
    },
    fetchItemStart: (state) => {
      state.isItemLoading = true
      state.data.item = null
    },
    fetchItemFailed: (state) => {
      state.isItemLoading = false
      state.isItemInitialized = false
      state.data.item = null
    },
    itemfetchSucceeded: (state, action: PayloadAction<ListingMapItem | PropertyMapItem>) => {
      state.isItemLoading = false
      state.isItemInitialized = true
      state.data.item = action.payload
    },
  },
})

export const { fetchStart, fetchFailed, mapDatafetchSucceeded, fetchItemStart, itemfetchSucceeded, fetchItemFailed } =
  mapSlice.actions

export const fetchMapData = (ownerId: string) => async (dispatch) => {
  try {
    dispatch(fetchStart())
    const mapData = await getMapData(ownerId)
    dispatch(mapDatafetchSucceeded(mapData))
  } catch (error) {
    dispatch(fetchFailed())
  }
}

export const fetchMapItem = (itemId: string, itemType: string) => async (dispatch) => {
  try {
    dispatch(fetchItemStart())
    const mapItem = await getMapItem(itemId, itemType)
    dispatch(itemfetchSucceeded(mapItem))
  } catch (error) {
    dispatch(fetchItemFailed())
  }
}

export default mapSlice.reducer
