import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { updateVersion } from './global/actions'
import accountReducer from './account'
import ownerReducer from './owner'
import suburbReducer from './suburb'
import mapReducer from './map'
import listingReducer from './listing'
import propertyReducer from './property'
import usersReducer from './users'
import loginProgressReducer from './login-progress'
import usersAccessListReducer from './users-access'

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    account: accountReducer,
    owner: ownerReducer,
    suburb: suburbReducer,
    map: mapReducer,
    listing: listingReducer,
    property: propertyReducer,
    users: usersReducer,
    loginProgress: loginProgressReducer,
    accessList: usersAccessListReducer,
  },
})

store.dispatch(updateVersion())

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch()

export default store
