import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Listing, ListingState } from '../types'
import { getListingData } from './helpers'

const initialState: ListingState = {
  isInitialized: false,
  isLoading: true,
  data: null,
}

export const listingSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true
    },
    fetchFailed: (state) => {
      state.isLoading = false
      state.isInitialized = false
    },
    datafetchSucceeded: (state, action: PayloadAction<Listing>) => {
      state.isLoading = false
      state.isInitialized = true
      state.data = action.payload
    },
  },
})

export const { fetchStart, fetchFailed, datafetchSucceeded } = listingSlice.actions

export const fetchListingData = (_id: string) => async (dispatch) => {
  try {
    dispatch(fetchStart())
    const listingData = await getListingData(_id)
    dispatch(datafetchSucceeded(listingData))
  } catch (error) {
    dispatch(fetchFailed())
  }
}

export default listingSlice.reducer
